import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

function Leadership() {
    useEffect(() => {
        Aos.init({ delay: 200, once: false });
    }, []);

    return (
        <section className="pt-20 pb-48 bg-golden-100 text-gray-900">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap justify-center text-center mb-24">
                    <div className="w-full lg:w-6/12 px-4" data-aos="fade-down" data-aos-duration="1000">
                        <h2 className="text-4xl font-bold text-center">
                            Meet Our <span className="text-white">Leadership</span>
                        </h2>
                        <p className="text-lg lg:text-md leading-relaxed text-center m-4">
                            Our forward-looking team is experienced, dedicated and focused
                            on helping your company meet its objectives.
                        </p>
                    </div>
                </div>
                <div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
                        <div data-aos="flip-right" data-aos-duration="1000">
                            <div className="px-6">
                                <img
                                    alt="..."
                                    src="images/CHENEY_PRUETT.jpg"
                                    className="shadow-lg p-1 bg-white rounded-full max-w-full mx-auto"
                                    style={{ maxWidth: "150px" }}
                                />
                                <div className="pt-3 text-center">
                                    <h5 className="text-2xl md:text-xl font-bold">
                                        Cheney Pruett
                                    </h5>
                                    <p className="mt-1 mb-10 text-xl md:text-sm lg:text-lg text-white uppercase font-medium">
                                        Chief Executive Officer
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div data-aos="flip-right" data-aos-duration="1500">
                            <div className="px-6">
                                <img
                                    alt="..."
                                    src="images/JOHN_HUMPHREY.jpg"
                                    className="shadow-lg rounded-full p-1 bg-white max-w-full mx-auto"
                                    style={{ maxWidth: "150px" }}
                                />
                                <div className="pt-3 text-center">
                                    <h5 className="text-2xl md:text-xl font-bold">
                                        John Humphrey
                                    </h5>
                                    <p className="mt-1 mb-10 text-xl md:text-sm lg:text-lg text-white uppercase font-medium">
                                        Chief Financial Officer
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div data-aos="flip-right" data-aos-duration="1500">
                            <div className="px-6">
                                <img
                                    alt="..."
                                    src="images/KIMBERLIN_MEADOR.jpg"
                                    className="shadow-lg rounded-full p-1 bg-white max-w-full mx-auto"
                                    style={{ maxWidth: "150px" }}
                                />
                                <div className="pt-3 text-center">
                                    <h5 className="text-2xl md:text-xl font-bold">
                                        Kimberlin Meador
                                    </h5>
                                    <p className="mt-1 mb-10 text-xl md:text-sm lg:text-lg text-white uppercase font-medium">
                                        Chief Administrative Officer
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div data-aos="flip-right" data-aos-duration="2000">
                            <div className="px-6">
                                <img
                                    alt="..."
                                    src="images/JOSH_LAMBERTH.jpg"
                                    className="shadow-lg rounded-full p-1 bg-white max-w-full mx-auto"
                                    style={{ maxWidth: "150px" }}
                                />
                                <div className="pt-3 text-center">
                                    <h5 className="text-2xl md:text-xl font-bold">
                                        Josh Lamberth
                                    </h5>
                                    <p className="mt-1 mb-10 text-xl md:text-sm lg:text-lg text-white uppercase font-medium">
                                        Senior VP of Data Science
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
                        <div data-aos="flip-left" data-aos-duration="2000">
                            <div className="px-6">
                                <img
                                    alt="..."
                                    src="images/WILL_PAN.jpg"
                                    className="shadow-lg rounded-full p-1 bg-white max-w-full mx-auto"
                                    style={{ maxWidth: "150px" }}
                                />
                                <div className="pt-3 text-center">
                                    <h5 className="text-2xl md:text-xl font-bold">
                                        Will Pan
                                    </h5>
                                    <p className="mt-1 mb-10 text-xl md:text-sm lg:text-lg text-white uppercase font-medium">
                                        Senior VP Analytics
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div data-aos="flip-left" data-aos-duration="1500">
                            <div className="px-6">
                                <img
                                    alt="..."
                                    src="images/JEFF_CROSS.jpg"
                                    className="shadow-lg rounded-full p-1 bg-white max-w-full mx-auto"
                                    style={{ maxWidth: "150px" }}
                                />
                                <div className="pt-3 text-center">
                                    <h5 className="text-2xl md:text-xl font-bold">
                                        Jeff Cross
                                    </h5>
                                    <p className="mt-1 mb-10 text-xl md:text-sm lg:text-lg text-white uppercase font-medium">
                                        VP of Customer Acquisition
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div data-aos="flip-left" data-aos-duration="2000">
                            <div className="px-6">
                                <img
                                    alt="..."
                                    src="images/DEANA_LANDRETH.jpg"
                                    className="shadow-lg rounded-full p-1 bg-white max-w-full mx-auto"
                                    style={{ maxWidth: "150px" }}
                                />
                                <div className="pt-3 text-center">
                                    <h5 className="text-2xl md:text-xl font-bold">
                                        Deana Landreth
                                    </h5>
                                    <p className="mt-1 mb-10 text-xl md:text-sm lg:text-lg text-white uppercase font-medium">
                                        VP of Compliance & Quality Assurance
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div data-aos="flip-left" data-aos-duration="2000">
                            <div className="px-6">
                                <img
                                    alt="..."
                                    src="images/JOSH_LAWRENCE.jpg"
                                    className="shadow-lg rounded-full p-1 bg-white max-w-full mx-auto"
                                    style={{ maxWidth: "150px" }}
                                />
                                <div className="pt-3 text-center">
                                    <h5 className="text-2xl md:text-xl font-bold">
                                        Josh Lawrence
                                    </h5>
                                    <p className="mt-1 mb-10 text-xl md:text-sm lg:text-lg text-white uppercase font-medium">
                                        Senior Site Reliability Engineer
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Leadership;